/**
* Google sign in CSS rules
* @author A TSR Developer
* @device mixed
*/

.googlesignin > .googlebutton {
    background: #5b8ab8;
    background: linear-gradient(to bottom, #5b8ab8 0%, #4260a6 100%);
    border-radius: 2px;
    color: white;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    height: 33px;
    line-height: 33px;
    padding-right: 10px;
    transition: all 0.2s;
    vertical-align: top;
}

.googlesignin > .googlebutton:before {
    background: url("//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/miscellaneous/googlelogo.png") no-repeat;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    content: "\a0";
    display: inline-block;
    height: 33px;
    margin-right: 10px;
    vertical-align: top;
    width: 33px;
}

.googlesignin > .googlebutton:hover {
    background: #40a0d4;
    background: linear-gradient(to bottom, #40a0d4 0%, #5a8bb9 100%);
}

.googlesignin > .googleerror {
    color: #c80d18;
    display: none;
    line-height: 18px;
    margin-bottom: 8px;
}

.googlesignin > .googleerror:before {
    background: url("//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/miscellaneous/sprite-ordered-form.png") no-repeat 0 -73px;
    content: "\a0";
    display: inline-block;
    height: 14px;
    vertical-align: top;
    width: 18px;
    margin: 2px 6px 2px 0;
}

@media (-webkit-min-device-pixel-ratio: 1.25),
(min-resolution: 120dpi) {
    .googlesignin > .googlebutton:before {
        background-image: url("//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/miscellaneous/googlelogo@2x.png");
        background-size: 33px 33px;
    }

    .googlesignin > .googleerror:before {
        background-image: url("//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/miscellaneous/sprite-ordered-form@2x.png");
        background-size: 29px 190px;
    }
}

.returninguser {
    margin: 0 0 30px;
}

.returninguser > p {
    color: #505050;
    font-size: 14px;
    line-height: 1.35;
    margin-top: 12px;
}

.googlesignin > h2 {
    border-bottom: 2px solid #44a0d4;
    color: #44a0d4;
    font-size: 25px;
    line-height: 50px;
}

.googlesignin > .gotaccount {
    color: #505050;
    float: right;
    font-size: 14px;
}

.googleconnected {
    color: #505050;
    font-size: 14px;
    height: 110px;
    margin: 0 0 30px;
    padding: 1px;
}

.gsignin.modal .googleconnected {
    height: auto;
    margin: 0 auto 25px auto;
    width: 300px;
}

.googleconnected > .googlepic {
    float: left;
    height: 105px;
    margin-right: 10px;
    width: 105px;
}

.googleconnected > .googlename {
    color: #141823;
    display: block;
    font-size: 14px;
    line-height: 22px;
}

.googlesignin > .googleconnected > .google-login-button {
    margin: 3px 0;
}

.googlesignin h3 {
    color: #44a0d4;
    font-size: 25px;
}

.ordered-form .errorblock {
    display: none;
    margin-top: 16px;
}

.googlesignin .interact {
    font-weight: normal;
    font-size: 14px;
}

.googlesignin-register .ordered-form .submit {
    text-align: center;
}

.googlesignin-register .ordered-form ol {
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
}
.googlesignin-register .ordered-form .radiofields {
    margin-bottom: 25px;
    padding-top: 11px;
}
.googlesignin-register .ordered-form .radiofields label {
    font-weight: normal;
}
.googlesignin-register .ordered-form .radiofields label.radiofields-label {
    margin: 0;
}
.googlesignin-register .ordered-form .radiofields .radio {
    box-sizing: border-box;
    margin-left: 4%;
    display: block;
    float: left;
    vertical-align: top;
    width: 29%;
}
.googlesignin-register .ordered-form .radiofields .radio.first {
    margin-left: 11px;
}
.googlesignin-register .ordered-form .radiofields fieldset label span {
    padding-top: 5px;
    padding-left: 5px;
}
.googlesignin-register .ordered-form .radiofields.checkbox.small input + span:before,
.googlesignin-register .ordered-form .radiofields.checkbox.small input + span:after{
    top: -4px;
}
.googlesignin-register .ordered-form .radiofields .error-message {
    clear: both;
}
.googlesignin-register .ordered-form .radiofields.checkbox.small input+span:before {
    width: 25px;
    height: 25px;
    left: -31px;
    top: -4px;
}
.googlesignin-register .ordered-form .radiofields.checkbox.small input:checked+span:after {
    background-position: 0 -44px;
    height: 15px;
    width: 22px;
    left: -28px;
    top: 2px;
}
.googlesignin-register .ordered-form .emailoptions span {
    font-size: 13px;
}

/* ## start-device-block mobile ##*/
@media screen and (max-width: 480px),
(-webkit-min-device-pixel-ratio: 1.25) and (max-width: 480px),
(min-resolution: 120dpi) and (max-width: 480px) {
    .googlesignin {
        padding-bottom: 20px;
    }

    .googlesignin > h2 {
        font-size: 19px;
        line-height: 25px;
        padding-bottom: 4px;
    }

    .googlesignin > .gotaccount {
        color: #666;
        float: none;
        font-size: 13px;
        margin-bottom: 16px;
    }

    .googlesignin > .gotaccount > strong {
        font-weight: normal;
    }

    .googlesignin > .googleconnected {
        margin-bottom: 16px;
        width: auto;
    }

    .googlesignin h3 {
        font-size: 16px;
    }

    .returninguser {
        margin-bottom: 16px;
    }

    .googlesignin .form-medium {
        width: auto;
    }

    .googlesignin .emailoptions {
        font-size: 13px;
    }

    .googlesignin .ordered-form .description + li {
        margin-top: 16px;
    }

    .datacontainer{
        word-wrap:break-word;
    }
    .ordered-form .description + li {
    margin:30px 0px 20px 0px;
    }

}
/* ## end-device-block ## */

.gsignincontainer {
    display: inline;
    float: left;
    margin-right: 20px;
    margin-top: -3px;
}

.abcRioButton {
    box-shadow: none;
}

.page-register .section-header {
    border: none;
}

.modal-inner-close {
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

.gsignin.modal {
    min-height: 618px;
    padding: 0 20px 20px;
}

.google-register-header {
    color: #666;
    font-size: 1.083em;
    line-height: 1.308em;
    margin: 15px 0 15px;
    position: relative;
    border-bottom: 2px solid #39c;
}

.gsignin.modal .google-register-header {
    background-color: #7cc6d7;
    border: 0;
    height: 60px;
    margin: 0 -20px 30px;
}

.google-register-header .standard-logo {
    position: absolute;
    top: 10px;
    left: 12px;
}

.google-register-header .standard-logo > img {
    height: 65px;
    width: 70px;
}

.google-register-header .standard-logo > .retina {
    display: none;
}

.google-register-header h1,
.googleDetails{
    color: #7B63DB;
    font-weight: normal;
    margin: 0px;
    font-size: 25px;
    font-family: "Museo Sans Cyrillic",Verdana,Arial,Geneva,Lucida,sans-serif;
}

.gsignin.modal .google-register-header h1,
.gsignin.modal .googleDetails {
    color: white;
    font-family: "Gibson", sans-serif;
    font-size: 22px;
    font-weight: bold;
    margin: 0;
}

.gsignin.modal .google-register-header h1 {
    line-height: 60px;
    text-align: center;
    text-rendering: geometricPrecision;
}

.gsignin.modal .googleDetails {
    font-size: 20px;
    color: #4a4a4a;
    text-align: center;
}

.google-register-header .gotaccount {
    color: #505050;
    float: right;
    font-size: 14px;
    font-family: Arial, Geneva, Lucida, sans-serif;
}

.googlepic, .googlename, .googlemail {
    float: left;
    display: inline;
}

.gsignin.modal .googlepic {
    height: 50px;
    width: 50px;
}

.datacontainer {
    margin: 35px 0px 0px 10px;
    float: left;
    font-family: Arial;
    width: 150px;
    font-weight: normal;
    font-size: 14px;
}

.gsignin.modal .datacontainer {
    line-height: 25px;
    margin: 0;
    width: auto;
}

.datacontainer div {
    margin: 0px 0px 10px 0px;
}

.gsignin.modal .datacontainer div {
    margin: 0;
}

#page_googlesignin .page-section  h3 {
    color: #7B63DB;
    font-size: 1.385em;
    font-weight: normal;
    line-height: 32px;
    margin: 0px;
}

#page_googlesignin .page-section .column-content {
    padding: 1px 29px 0px 30px;
}

.google-register-header .gotaccount {
    margin: 17px 0px 0px 0px;
}

.google-error {
    color: #c80d18;
    display: none;
    line-height: 18px;
    margin-bottom: 8px;
}

.google-error:before {
    background: url("//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/miscellaneous/sprite-ordered-form.png") no-repeat 0 -73px;
    content: "\a0";
    display: inline-block;
    height: 14px;
    vertical-align: top;
    width: 18px;
    margin: 2px 6px 2px 0;
}

@media (-webkit-min-device-pixel-ratio: 1.25),
(min-resolution: 120dpi) {
    .google-error:before {
        background-image: url("//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/miscellaneous/sprite-ordered-form@2x.png");
        background-size: 29px 190px;
    }
}

.gsignin.modal .returninguser h3 {
    color: #44a0d4;
    font-size: 22px;
}

.gsignin .returninguser-choice .bubble:first-child {
    margin-right: 8px;
}

/* ## start-device-block mobile ##*/
@media (max-width: 480px) {
    .gsignincontainer {
        margin: -3px 0px 13px 0px;
        clear: both;
    }

    .google-register-header {
        margin: 8px 0px 15px 0px;
    }

    .google-register-header .gotaccount {
        display: none;
    }

    .google-register-header h1, h1 {
        line-height: 25px;
        font-size: 19px;
    }

    #page-columns .column.main .column-content {
        padding: 0px 0px 0px 0px;
        margin: 0px 10px 0px 0px;
    }

    #page-columns .column.main {
        margin: 10px 0px 0px 0px;
    }
}
/* ## end-device-block ## */

@media screen and (max-width: 609px) {
    .modal-inner-close {
        background: url("//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/register/custom/mobile/close.svg") no-repeat center center / 12px 12px;
        line-height: 27px;
        overflow: hidden;
        text-indent: 1000px;
        width: 27px;
    }

    .gsignin.modal > .google-register-header {
        height: 50px;
        margin-bottom: 20px;
    }

    .gsignin.modal > .google-register-header > h1 {
        font-size: 18px;
        line-height: 50px;
    }

    .google-register-header .standard-logo {
        left: 10px;
        top: 6px;
    }

    .google-register-header .standard-logo > img {
        height: 36px;
        width: 40px;
    }

    .gsignin.modal .googlesignin-register .emailoptions br {
        display: none;
    }
}
